<script setup lang="ts">
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import SolutionsSection from '~/pages/solutions/components/SolutionsSection.vue'
import FooterCta from '~/pages/components/new/FooterCta.vue'
import BlogSection from '~/pages/components/new/landing/BlogSection.vue'
import StatsSection from '~/pages/components/new/StatsSection.vue'
import Footer from './new/Footer.vue'

const settings = useLandingPageSettings()

</script>

<template>
    <!-- <div>
        <div class="relative isolate px-6 pt-14 lg:px-8">
            <div class="mx-auto max-w-2xl py-12 sm:py-16 lg:py-24">
                <div class="hidden sm:mb-8 sm:flex sm:justify-center">
                    <NuxtLink
                        v-if="settings.eyebrowUrl"
                        :href="settings.eyebrowUrl"
                        target="_blank"
                    >
                        <div
                            class="relative mb-6 rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20 sm:mb-0"
                        >
                            {{ settings.eyebrowText }}
                            <span class="font-semibold text-green-600"><span
                                class="absolute inset-0"
                                aria-hidden="true"
                            />Read more
                                <span aria-hidden="true">&rarr;</span></span>
                        </div>
                    </NuxtLink>
                </div>
                <div class="text-center">
                    <h1
                        class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
                        v-html="settings.mainHeroTitle"
                    >
                    </h1>
                    <div class="mt-6 text-lg leading-8 text-gray-600"
                    v-html="settings.mainHeroSubtitle">
                    </div>
                    <div
                        class="mb-10 mt-14 flex items-center justify-center gap-x-6"
                    >
                        <NuxtLink
                            v-if="settings.livePreviewUrl"
                            :href="settings.livePreviewUrl"
                            class="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-5 py-3.5 text-lg font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            Live preview
                            <ArrowTopRightOnSquareIcon
                                class="-mr-0.5 h-6 w-6"
                                aria-hidden="true"
                            />
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>

        <SolutionsSection :card="true" />

        <StatsSection />

        <BlogSection />

        <Footer />
    </div> -->

    <div class="relative isolate overflow-hidden bg-white">
      <!-- <svg class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
        <defs>
          <pattern id="0787a7c5-978c-4f66-83c7-11c213f99cb7" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" stroke-width="0" fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" />
      </svg> -->
      <div class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
            <div class="shrink-0">
                    <NuxtLink to="/home">
                        <img
                            class="h-12 w-auto"
                            src="/horizontal.svg"
                            alt="Civiqa"
                        />
                    </NuxtLink>
                </div>
          <div class="mt-24 sm:mt-32 lg:mt-16">
            <!-- <a href="#" class="inline-flex space-x-6">
              <span class="rounded-full bg-green-600/10 px-3 py-1 text-sm font-semibold leading-6 text-green-600 ring-1 ring-inset ring-green-600/10">What's new</span>
              <span class="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                <span>Just shipped v1.0</span>
                <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </a> -->
          </div>
          <h1 class="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" v-html="settings.mainHeroTitle"></h1>
          <p class="mt-6 text-lg leading-8 text-gray-600" v-html="settings.mainHeroSubtitle"></p>
          <div class="mt-10 flex items-center gap-x-6">
            <a href="/signup" class="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Get started</a>
            <a v-if="settings.livePreviewUrl" :href="settings.livePreviewUrl" class="text-sm font-semibold leading-6 text-gray-900">See preview <span aria-hidden="true">→</span></a>
          </div>
        </div>
        <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            
              <img src="/bill-boards-mac.png" alt="App screenshot" width="2432" height="1442" class="w-[76rem]" />
            
          </div>
        </div>
      </div>
      <SolutionsSection :card="true" />
    </div>
    <Footer />
  </template>
  